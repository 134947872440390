import React, { useCallback } from "react";
import { Box, Button } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import Chip from "../../../components/Chip";

const CustomerFilterChips = ({ selectedFilters, setSelectedFilters, filterValues, globalCount }) => {
  const clearFilters = useCallback(() => {
    setSelectedFilters({
      customerCategory: [],
      priority: [],
      alertType: [],
      alertStatus: [],
      vehicleMake: []
    });

    globalCount.current = 1;
    /* istanbul ignore next */
    localStorage.setItem('globalCount', globalCount.current);
  }, [setSelectedFilters]);

   /* istanbul ignore next */
  const handleFilterRemove = (filterKey, filterValue) => {
    setSelectedFilters(prev => {
      if (filterKey === 'alertStatus') {
        let newValues;
        if (filterValue === 'allActive') {
          newValues = prev[filterKey].filter(item => !filterValues?.activeAlertStatus?.includes(item.value));
        } else if (filterValue === 'allInactive') {
          newValues = prev[filterKey].filter(item => !filterValues?.inActiveAlertStatus?.includes(item.value));
        } else {
          newValues = prev[filterKey].filter(item => item.value !== filterValue);
        }
        return { ...prev, [filterKey]: newValues };
      }
      return {
        ...prev,
        [filterKey]: prev[filterKey].filter(item => item.value !== filterValue)
      };
    });
  };

  /* istanbul ignore next */
  const generateAllChips = () => {
    // Flatten and sort all values with positions
    const allValuesWithPositions = Object.entries(selectedFilters).flatMap(([filterKey, values]) =>
      values.map(item => ({ ...item, filterKey }))
    );

    const sortedValues = allValuesWithPositions.sort((a, b) => a.position - b.position);

    // Determine if all active or inactive statuses are selected
    const isAllActive = filterValues?.activeAlertStatus?.every(value =>
      sortedValues.some(item => item.value === value)
    );

    const isAllInactive = filterValues?.inActiveAlertStatus?.every(value =>
      sortedValues.some(item => item.value === value)
    );

    const chips = [];

    if (isAllActive) {
      chips.push(
        <Chip
          key="all-active-alerts"
          value="All Active Alerts"
          onDelete={() => handleFilterRemove('alertStatus', 'allActive')}
        />
      );
    }

    if (isAllInactive) {
      chips.push(
        <Chip
          key="all-inactive-alerts"
          value="All Inactive Alerts"
          onDelete={() => handleFilterRemove('alertStatus', 'allInactive')}
        />
      );
    }

    sortedValues.forEach(({ filterKey, value }) => {
      if (
        (filterKey === 'alertStatus' && ((filterValues.activeAlertStatus?.includes(value) && isAllActive) ||
          (filterValues.inActiveAlertStatus?.includes(value) && isAllInactive)))
      ) {
        return;
      }

      chips.push(
        <Chip
          key={`${filterKey}-${value}`}
          value={`${value}`}
          onDelete={() => handleFilterRemove(filterKey, value)}
        />
      );
    });

    return chips;
  };


  const allChips = generateAllChips();

  return (
    <>
      <Box display="flex" flexWrap="wrap" sx={{ marginLeft: '4px', marginBottom: "2rem", marginTop: '15px', background: '#ffffff', borderRadius: '16px', height: '2rem', border: '0', textTransform: "capitalize", minHeight: '2rem', gap: '10px' }}>
        {allChips}
        {allChips.length >= 2 && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={clearFilters}
            style={{ border: "none" }}
          >
            <CloseSharp style={{ color: "#0562D2" }} />
            <span style={{ marginLeft: '4px', color: "#0562D2", fontWeight: "700", lineHeight: "20px" }}>Clear All</span>
          </Button>
        )}
      </Box>
    </>
  )
}

export default CustomerFilterChips;