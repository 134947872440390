import React from "react";
import PropTypes from 'prop-types';
import Legend from "../../../components/Legend";
import { Typography, Icon } from "@ford/ford-ui-components";

const ChartMetricsCard = ({ title, loading, data, chartComponent: ChartComponent, colorMapping }) => {
    return (
        <div style={{...cardStyle, height: `${loading ? "480px" : "auto"}`}}>
            {!loading && <div style={{  padding: "80px 0 0 130px" }}>
                <Typography spanProps={{
                    className: "subtitle-bold"
                }} displayColor="text-onlight-moderate-default">
                    {title}
                </Typography>
            </div>}
            <div style={{ height: '100%', width: '100%' }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        <Icon name="loading" />
                    </div>
                ) : (
                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap" }}>
                        <div>
                            <ChartComponent data={data} colorMapping={colorMapping} />
                        </div>
                        <div>
                            <Legend colorMapping={colorMapping} data={data} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ChartMetricsCard.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    chartComponent: PropTypes.elementType.isRequired,
    colorMapping: PropTypes.object.isRequired,
};

const cardStyle = {
    border: '1px solid #E5E5E5',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: 0,
    marginTop: '10px',
    paddingBottom: '15px',
};

export default ChartMetricsCard;
