import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

//styling
import { styles } from "./IssueDetailHeaderStyles.style";

const IssueDetailHeader = ({ handleDrawerClose, customerDetails }) => {

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } 

  return (
    <Box>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: 6,
        paddingY: 2,
        fontSize: "0.8rem",
      }}>
        <Box sx={styles.titleContainer}>
          {customerDetails?.customerInfo?.firstName || customerDetails?.customerInfo?.lastName ? (
            <>
              <Box>
                <Typography sx={styles.titleTypography}>
                  {toTitleCase(customerDetails?.customerInfo?.firstName)}
                </Typography>
              </Box>
              <Box sx={{marginLeft: "7px"}}>
                <Typography sx={styles.titleTypography}>
                  {toTitleCase(customerDetails?.customerInfo?.lastName)}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography sx={styles.titleTypography}>
              Name Unknown
            </Typography>
          )}
        </Box>
        <Box>
          <IconButton sx={styles.closeButton} onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{padding: "0 50px", marginBottom: "30px", marginTop: "-15px"}}>
      <Box sx={styles.titleContainer}>
        <Box>
          <Typography sx={styles.subtitleTypography} variant="h6">
            {`${customerDetails?.vehicleInfo?.vehicleModelYear ?? "-"} ${(customerDetails?.vehicleInfo?.vehicleMake)?.toUpperCase() ?? "-"} ${customerDetails?.vehicleInfo?.vehicleModel ?? "-"}`}
          </Typography>
        </Box>
        <Box sx={{
          width: '2px',
          height: "15px",
          backgroundColor: '#000000', 
          display: 'inline-block',
          margin: '3px -2px', // Add some margin for space
        }}>
        </Box>
        <Box>
          <Typography sx={styles.subtitleTypography} variant="h6">
            {`${customerDetails?.vehicleInfo?.vin}`}
          </Typography>
        </Box>
      </Box>
      </Box>
    </Box>
  );
};



IssueDetailHeader.propTypes = {
  customerDetails: PropTypes.object,
  handleDrawerClose: PropTypes.func,
};

export default IssueDetailHeader;
