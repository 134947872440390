// src/components/PriorityBadge.js
import React from 'react';

const getPriorityStyles = (priority) => {
  const styles = {
    Urgent: { bgColor: '#F9E8EA' },
    High: { bgColor: '#F9E8EA' },
    Medium: { bgColor: '#FCF2E6' },
    Low: { bgColor: '#E5F3E6' }
  };
  return styles[priority] || { dotColor: '#000', bgColor: '#E5E5E5', borderColor: '#8B0000' };
};

const PriorityBadge = ({ priority }) => {
  const { bgColor } = getPriorityStyles(priority);

  return (
    <span
      style={{
        display: 'inline-flex',
        width: '78px',
        fontSize: '16px',
        height: '24px',
        padding: '2px 8px',
        backgroundColor: bgColor,
        borderRadius: '3px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      {priority}
    </span>
  );
};

export default PriorityBadge;
