// src/App.js
import React from 'react';
import './App.css';
import { Box, ThemeProvider, CssBaseline } from '@mui/material';
import theme from './config/theme';
import AppHeader from './components/AppHeader';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppRoutes from './router/AppRoutes';
import LoginPage from './containers/Auth/LoginPage';
import UnauthorizedPage from './containers/Auth/UnauthorizedPage';
import { RoleProvider } from './context/RoleContext';
import GlobalStateProvider from './context/GlobalStateContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <RoleProvider>
          <GlobalStateProvider>
            <Routes>
              <Route path='/login' element={<LoginPage />} />
              <Route path='/unauthorized' element={<UnauthorizedPage />} />
              <Route
                path='*'
                element={
                  <>
                    <AppHeader />
                    <Box sx={styles.container}>
                      <Box component={'main'} sx={styles.mainSection}>
                        <AppRoutes />
                      </Box>
                    </Box>
                  </>
                }
              />
            </Routes>
          </GlobalStateProvider>
        </RoleProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

/** @type {import("@mui/material").SxProps} */
const styles = {
  container: {
    display: 'flex',
    bgcolor: '#fff',
    height: '100vh'
  },
  mainSection: {
    p: 1,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
};

export default App;
