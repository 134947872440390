import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";
import { styles } from "./IssueDetailprofile.style";
import { titleCase } from '../../../utils/stringUtils';

const getRange = (engineType, fuelRange, batteryRange) => {
    switch (engineType) {
        case 'ICE':
        case 'Hybrid':
            return fuelRange;
        case 'Electric':
            return batteryRange;
        default:
            return null;
    }
};

const formatUpdateStatus = (tmcStatusCode, tmcUpdatedTime) => {
    switch (tmcStatusCode) {
        case 200:
            return `Updated on ${moment(tmcUpdatedTime).format('MM-DD-YYYY')}`;
        case 403:
            return 'Updated on Not Available';
        default:
            return 'Unable to fetch data';
    }
};

const getModemStatus = (tmcStatusCode) => {
    switch (tmcStatusCode) {
        case 200:
            return "Authorized";
        case 403:
            return "Unauthorized, data unavailable";
        default:
            return "-";
    }
};


const getEngineTypeDisplay = (engineType) => {
    return engineType === "Electric" ? 'EV' : (engineType || '-');
};

const formatValue = (value, suffix = '') => {
    return (value !== undefined && value !== null) ? `${value}${suffix}` : '-';
};

const VehicleInfo = ({ customerDetails, vehicleData, loading }) => {

    const range = getRange(vehicleData?.engineType, vehicleData?.fuelRange, vehicleData?.batteryRange);
    const updatedOn = formatUpdateStatus(vehicleData?.tmcStatusCode, vehicleData?.tmcUpdatedTime);
    const engineTypeDisplay = getEngineTypeDisplay(vehicleData?.engineType);
    const odometerDisplay = formatValue(vehicleData?.odometer, ' miles');
    const fuelLevelDisplay = formatValue(vehicleData?.fuelLevel, '% full');
    const batteryStateOfChargeDisplay = formatValue(vehicleData?.batteryStateOfCharge, '%');
    const engineOilPercentDisplay = formatValue(vehicleData?.engineOilPercent, '%');

    return (
        <div style={{ margin: "5px 50px 0 50px", padding: "15px", background: "#FAFAFA" }}>
            <div>
                <div style={styles.profileHeadingContainer}>
                    <div style={styles.profileHeadingText}>
                        Vehicle Information
                    </div>
                    <div style={styles.profileTimestamp}>
                        {!loading && updatedOn}
                    </div>
                </div>
                <table style={{ margin: "20px 0" }}>
                    <tr style={{}}>
                        <td style={{ width: "250px", wordBreak: "break-word", overflowWrap: "break-word" }}>
                            <p style={styles.profileTimestamp}>Engine Type</p>
                            <p style={styles.profileDataText}>{engineTypeDisplay}</p>
                        </td>
                        <td>
                            <p style={styles.profileTimestamp}>Modem Status</p>
                            <p style={styles.profileDataText}>{getModemStatus(vehicleData?.tmcStatusCode)}</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "250px", padding: "10px 0" }}>
                            <p style={styles.profileTimestamp}>Trim</p>
                            <p style={styles.profileDataText}>
                                {customerDetails?.vehicleInfo?.vehicleTrim ? titleCase(customerDetails?.vehicleInfo?.vehicleTrim) : '-'}
                            </p>
                        </td>
                        {<td style={{ width: "250px", padding: "10px 0" }}>
                            <p style={styles.profileTimestamp}>Odometer</p>
                            <p style={styles.profileDataText}>
                                {vehicleData?.engineType ? odometerDisplay : '-'}
                            </p>
                        </td>}
                    </tr>
                    <tr>
                        <td style={{ width: "250px", padding: "10px 0" }}>
                            <p style={styles.profileTimestamp}>Color</p>
                            <p style={styles.profileDataText}>{customerDetails?.vehicleInfo?.vehicleColor ? titleCase(customerDetails?.vehicleInfo?.vehicleColor) : '-'}</p>
                        </td>
                        {(vehicleData?.engineType === 'ICE' || vehicleData?.engineType === "Hybrid" || vehicleData?.engineType === null) && (
                            <td style={{ width: "250px", padding: "10px 0" }}>
                                <p style={styles.profileTimestamp}>Fuel Level</p>
                                <p style={styles.profileDataText}>
                                    {vehicleData?.engineType ? fuelLevelDisplay : '-'}
                                </p>
                            </td>
                        )}
                        {(vehicleData?.engineType === 'Electric') && (
                            <td style={{ width: "250px", padding: "10px 0" }}>
                                <p style={styles.profileTimestamp}>State of Charge</p>
                                <p style={styles.profileDataText}>
                                    {vehicleData?.engineType ? batteryStateOfChargeDisplay : '-'}
                                </p>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td style={{ width: "250px", padding: "10px 0" }}>
                            <p style={styles.profileTimestamp}>Range</p>
                            <p style={styles.profileDataText}>{(range !== undefined && range !== null) ? `${range} miles` : '-'}</p>
                        </td>
                        {(vehicleData?.engineType === 'ICE' || vehicleData?.engineType === "Hybrid" || vehicleData?.engineType === null) && (
                            <td style={{ width: "250px", padding: "10px 0" }}>
                                <p style={styles.profileTimestamp}>Engine Oil Life</p>
                                <p style={styles.profileDataText}>
                                    {vehicleData?.engineType ? engineOilPercentDisplay : '-'}
                                </p>
                            </td>
                        )}
                    </tr>
                </table>
            </div>
        </div>
    );
}

VehicleInfo.propTypes = {
    customerDetails: PropTypes.shape({
        vehicleInfo: PropTypes.shape({
            vehicleTrim: PropTypes.string,
            vehicleColor: PropTypes.string,
        }),
    }),
    vehicleData: PropTypes.shape({
        engineType: PropTypes.oneOf(['ICE', 'Hybrid', 'Electric']),
        fuelRange: PropTypes.number,
        batteryRange: PropTypes.number,
        tmcStatusCode: PropTypes.number,
        tmcUpdatedTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        odometer: PropTypes.number,
        fuelLevel: PropTypes.number,
        batteryStateOfCharge: PropTypes.number,
        engineOilPercent: PropTypes.number,
    }),
};

export default VehicleInfo;