import React from "react";
import PropTypes from "prop-types";
import AlertItem from './AlertItem';

import { Box, Snackbar, Alert, Grid } from "@mui/material";
import { useRole } from '../../../context/RoleContext';
import { hasPageAccess, hasActionAccess } from '../../../utils/permissions';
import { Typography } from '@ford/ford-ui-components';
import { useAlertStatus } from "../../../hooks/useAlertStatus";

import issueService from "../../../services/issueService";
import ServiceNote from "./ServiceNote";

const IssueDetailService = ({ state, setState, isCustomers, selectedFilters, customerDetails }) => {
  const { roleConfig } = useRole();
  const hasProfileServicePermission = hasActionAccess(roleConfig, 'alert_detail', 'service_history');
  const vehicleProfileId = customerDetails?.vehicleInfo?.vehicleProfileId;

  const canReadNotes = hasPageAccess(roleConfig, 'service_note', 'read');
  const canWriteNotes = hasPageAccess(roleConfig, 'service_note', 'write');

  const {
    selectedAlertStatuses,
    handleStatusChange,
    snackbarMessage,
    snackbarOpen,
    setSnackbarOpen,
  } = useAlertStatus(roleConfig, isCustomers, state, setState, selectedFilters);
  
  /* istanbul ignore next */
  return (
    <div style={{ padding: "10px" }}>
      {hasProfileServicePermission && (
        <Box>
          <Box sx={{ marginLeft: 8, marginBottom: 1, marginTop: 1 }}>
            <Typography
              displayColor="text-onlight-strong"
              displayStyle="subtitle-semibold"
            >
              Active Alerts
            </Typography>
          </Box>
          {customerDetails?.activeAlerts?.length > 0 ? (
            customerDetails.activeAlerts.map((alert, index) => (
              <AlertItem
                key={alert.id}
                alert={alert}
                selectedAlertStatuses={selectedAlertStatuses}
                handleStatusChange={(event) => {
                  /* istanbul ignore next */
                  return handleStatusChange(event, alert.id, customerDetails.vehicleInfo.vehicleProfileId, (customerDetails?.activeAlerts?.length > 0 && customerDetails?.inActiveAlerts?.length > 0), alert.status, alert)
                }}
                index={index} // Pass the index here
              />
            ))
          ) : (
            <NoAlertsFound message="No active alerts found" />
          )}
        </Box>
      )}
      <ServiceNote
        vehicleProfileId={vehicleProfileId}
        issueService={issueService}
        canReadNotes={canReadNotes}
        canWriteNotes={canWriteNotes}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const NoAlertsFound = ({ message }) => (
  /* istanbul ignore next */
  <Grid container>
    <Grid sx={{
      height: "48px",
      width: "100%",
      padding: "12px 18px",
      margin: "0 45px",
      background: "#FAFAFA"
    }} item>
      {message}
    </Grid>
  </Grid>
);

IssueDetailService.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default IssueDetailService;
