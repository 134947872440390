import React from "react";
import PropTypes from "prop-types";
import AlertItem from '../IssueDetailService/AlertItem'; // Import the AlertItem component

import { Box, Grid, Snackbar, Alert } from "@mui/material";
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess } from '../../../utils/permissions';
import { Typography } from '@ford/ford-ui-components';
import { useAlertStatus } from "../../../hooks/useAlertStatus";

const AlertDetailHistory = ({ state, setState, isCustomers, selectedFilters, customerDetails }) => {
  const { roleConfig } = useRole();
  const hasProfileServicePermission = hasActionAccess(roleConfig, 'alert_detail', 'service_history');

  const {
    selectedAlertStatuses,
    handleStatusChange,
    snackbarMessage,
    snackbarOpen,
    setSnackbarOpen,
  } = useAlertStatus(roleConfig, isCustomers, state, setState, selectedFilters);

  return (
    <div style={{ padding: "10px" }}>
      {hasProfileServicePermission && (
        <Box>
          <Box sx={{marginLeft: 8, marginBottom: 1, marginTop: 1}}>
            <Typography
              displayColor="text-onlight-strong"
              displayStyle="subtitle-semibold"
            >
              Alerts History
            </Typography>
          </Box>
          {customerDetails?.inActiveAlerts?.length > 0 ? (
            /* istanbul ignore next */
            customerDetails.inActiveAlerts.map((alert, index) => (
              /* istanbul ignore next */
              <AlertItem
                key={alert.id}
                alert={alert}
                selectedAlertStatuses={selectedAlertStatuses}
                handleStatusChange={(event) => {
                  /* istanbul ignore next */
                  return handleStatusChange(event, alert.id, customerDetails.vehicleInfo.vehicleProfileId, (customerDetails?.activeAlerts?.length > 0 && customerDetails?.inActiveAlerts?.length > 0), alert.status, alert)
                }}
                index={index} // Pass the index here
              />
            ))
          ) : (
            <NoAlertsFound />
          )}
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const NoAlertsFound = () => (
  <Grid container >
    <Grid sx={{ 
      height: "48px", 
      width: "100%", 
      padding: "12px 18px", 
      margin: "0 45px", 
      background: "#FAFAFA" 
      }} item>
        No completed or cancelled alerts
      </Grid>
  </Grid>
);

AlertDetailHistory.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default AlertDetailHistory;
