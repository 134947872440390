import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Autocomplete, InputAdornment, IconButton, TextField } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import customerService from '../../services/customerService';
import debounce from 'lodash.debounce';
import { track } from '@amplitude/analytics-browser';

const CustomerSearch = ({ onVinSelect, selectedFilters, setIsApiError }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false); // State to track fetching status
  const globalCount = useRef(parseInt(localStorage.getItem('globalCount')) || 1);

  const fetchSuggestions = useCallback(async (search) => {
    try {
      setIsFetching(true); // Set fetching to true when starting to fetch
      /* istanbul ignore next */
      const filterParams = Object.entries(selectedFilters)
        .filter(([_, values]) => values.length > 0)
        .map(([filterKey, filterValues]) => ({ filterKey, filterValues: filterValues.map(item => item.value) }));

      const response = await customerService.getCustomerSuggestions(search, filterParams);
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setIsApiError({
        isError: true,
        message: error.message,
        code: error.response.status
      });
    } finally {
      setIsFetching(false); // Reset fetching status once fetching is done
    }
  }, [selectedFilters]);

  const debouncedFetchSuggestions = useCallback(
    debounce((search) => {
      if (search.length > 2) {
        fetchSuggestions(search);
      } else {
        /* istanbul ignore next */
        setSuggestions([]);
      }
    }, 300),
    [fetchSuggestions]
  );

  useEffect(() => {
    debouncedFetchSuggestions(searchTerm);
    return () => {
      debouncedFetchSuggestions.cancel();
    };
  }, [searchTerm, debouncedFetchSuggestions]);

  const handleSuggestionSelect = (event, value) => {
    if (value) {
      track('Search Selected', {
        searchTerm: searchTerm,
        vin: value.vin,
        customerName: value.customerName,
      });
      // Ensure `vin` is added to selectedFilters
    const updatedFilters = {
      ...selectedFilters,
      vin: [{ value: value.vin, position: globalCount.current++ }]
    };

    // Update the filters in local storage
    localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));

      onVinSelect(value.vin);
      setSearchTerm(''); // Clear the input after selection
      setSuggestions([]); // Clear suggestions after selection
    }
  };
/* istanbul ignore next */
  const handleClear = () => {
    setSearchTerm('');
    setSuggestions([]);
  };

  return (
    <Autocomplete
      options={suggestions?.length > 0 ? suggestions : []}
      getOptionLabel={(option) => `${option.customerName} - ${option.vin}`}
      onChange={handleSuggestionSelect}
      inputValue={searchTerm}
      onInputChange={(event, value, reason) => {
        // Only update searchTerm if the input value is changed by typing
        if (reason === 'input') {
          setSearchTerm(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search by Customer Name or VIN"
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" style={{ marginLeft: 10, marginRight: 0 }}>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      noOptionsText={
        searchTerm.length === 0
          ? "Enter 3 characters to search"
          : searchTerm.length < 3
          ? "Enter 3 characters to search"
          : isFetching
          ? "Fetching results..."
          : "No search results found"
      }
      disableClearable={true}
      style={{ width: 400 }}
    />
  );
};

export default CustomerSearch;