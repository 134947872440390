import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, Button, Menu, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomerFilterChips from './CustomerFilterChips/CustomerFilterChips';
import { track } from '@amplitude/analytics-browser';
import { hasActionAccess } from '../../utils/permissions';

const FilterSection = ({ filterValues, selectedFilters, setSelectedFilters, globalCount, roleConfig }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFilterKey, setCurrentFilterKey] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    return () => {
      localStorage.setItem('globalCount', globalCount.current);
    };
  }, []);

  const handleFilterMenuOpen = (event, filterKey) => {
    setAnchorEl(event.currentTarget);
    setCurrentFilterKey(filterKey);
    setIsMenuOpen(true);
  };

  /* istanbul ignore next */
  const handleFilterMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  /* istanbul ignore next */
  const handleFilterChange = useCallback((filterKey, value) => {
    setSelectedFilters(prev => {
      const currentIndex = prev[filterKey].findIndex(item => item.value === value);
      const newSelected = [...prev[filterKey]];

      if (currentIndex === -1) {
        newSelected.push({ value, position: globalCount.current++ });
        track('Filter Clicked', {
          filterKey,
          value,
          action: 'selected',
        });
      } else {
        newSelected.splice(currentIndex, 1);
        if (globalCount.current > 1) {
          globalCount.current--;
        }
        track('Filter Clicked', {
          filterKey,
          value,
          action: 'deselected',
        });
      }
      localStorage.setItem('selectedFilters', JSON.stringify({ ...prev, [filterKey]: newSelected }));
      localStorage.setItem('globalCount', globalCount.current);

      return { ...prev, [filterKey]: newSelected };
    });
  }, [setSelectedFilters]);

  /* istanbul ignore next */
  const handleAlertStatusChange = useCallback((groupKey, isChecked) => {
    const groupValues = filterValues[groupKey];
    
    setSelectedFilters(prev => {
      const currentAlertStatus = prev.alertStatus;
  
      let newAlertStatus;
      if (isChecked) {
        // Add new values to alertStatus with position
        newAlertStatus = [
          ...currentAlertStatus,
          ...groupValues
            .filter(value => !currentAlertStatus.some(item => item.value === value))
            .map(value => ({ value, position: globalCount.current++ }))
        ];
      } else {
        // Remove values from alertStatus
        newAlertStatus = currentAlertStatus.filter(item => !groupValues.includes(item.value));
      }
      
      // Determine the action based on isChecked
      const action = isChecked ? 'selected' : 'deselected';
      const alertType = groupKey === 'activeAlertStatus' ? 'All Active Alerts' : 'All Inactive Alerts';
      
      // Track the action
      track('Filter Clicked', {
        filterKey: 'alertStatus',
        value: alertType,
        action: action,
      });

      // Close the menu after update
      handleFilterMenuClose();
  
      return { ...prev, alertStatus: newAlertStatus };
    });
  }, [filterValues, setSelectedFilters, globalCount]);
  

  const renderFilterDropdown = (label, filterKey, options) => {
    const hasFilterAccess = hasActionAccess(roleConfig, 'list_customer', `filter_by_${filterKey}`.toLowerCase());
    if (!hasFilterAccess) return null;

    const isOpen = isMenuOpen && currentFilterKey === filterKey;
    let filterCount = selectedFilters[filterKey]?.length > 0 ? `(${selectedFilters[filterKey].length})` : '';
    let menuHeadLabel = label + ' ' + filterCount;
    return (
      <Box sx={{ m: 1 }}>
        <Button
          variant="outlined"
          onClick={(event) => handleFilterMenuOpen(event, filterKey)}
          endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            borderRadius: '50px',
            textTransform: 'capitalize',
            fontWeight: '700',
            border: "1px solid #000000"
          }}
        >
          {menuHeadLabel}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleFilterMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            borderRadius: '50px', // Reduce the border radius here
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                width: "200px",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                padding: "4px 10px",
              }}
              key={option}
              value={option}
              onClick={() => handleFilterChange(filterKey, option)}
            >
              <Checkbox
                checked={selectedFilters[filterKey].some(item => item.value === option)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2",
                  },
                  margin:"2px",
                  padding: "4px",
                }}
              />
              <ListItemText
                primary={option}
                primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const renderAlertStatusDropdown = (label) => {
    const hasFilterAccess = hasActionAccess(roleConfig, 'list_customer', 'filter_by_alertstatus');
    if (!hasFilterAccess) return null;

    const isOpen = isMenuOpen && currentFilterKey === 'alertStatus';
    const activeAlertStatus = filterValues.activeAlertStatus || [];
    const inActiveAlertStatus = filterValues.inActiveAlertStatus || [];
    let filterCount = selectedFilters['alertStatus']?.length > 0 ? `(${selectedFilters['alertStatus'].length})` : '';
    let menuHeadLabel = label + ' ' + filterCount;

    return (
      <Box sx={{ m: 1 }}>
        <Button
          variant="outlined"
          onClick={(event) => handleFilterMenuOpen(event, 'alertStatus')}
          endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          sx={{
            borderRadius: '50px',
            textTransform: 'capitalize',
            fontWeight: "700",
            border: "1px solid #000000"
          }}
        >
          {menuHeadLabel}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleFilterMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem
            sx={{
              width: "200px",
              padding: "8px",
              fontSize: "14px",
              color: "#333333",
              margin: 0, 
              padding: "4px 10px",         
            }}
            onClick={() => handleAlertStatusChange('activeAlertStatus', !activeAlertStatus.every(value => selectedFilters.alertStatus.some(item => item.value === value)))}
          >
            <Checkbox
              checked={activeAlertStatus.every(value => selectedFilters.alertStatus.some(item => item.value === value))}
              sx={{
                color: "#808080",
                '&.Mui-checked': {
                  color: "#0562D2",
                },
                padding: "4px"
              }}
            />
            <ListItemText primary="All Active Alerts" primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }} />
          </MenuItem>
          {activeAlertStatus.map((option) => (
            <MenuItem
              sx={{
                width: "200px",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                padding: "4px 24px",
              }}
              key={option} value={option} onClick={() => handleFilterChange('alertStatus', option)}>
              <Checkbox
                checked={selectedFilters.alertStatus.some(item => item.value === option)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2",
                  },
                  padding: "4px"
                }}
              />
              <ListItemText primary={option} primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }} />
            </MenuItem>
          ))}
          <MenuItem
            sx={{
              width: "200px",
              padding: "8px",
              fontSize: "14px",
              color: "#333333",
              marginTop: "5px",
              padding: "4px 10px", 
            }}
            onClick={() => handleAlertStatusChange('inActiveAlertStatus', !inActiveAlertStatus.every(value => selectedFilters.alertStatus.some(item => item.value === value)))}
            >
            <Checkbox
              checked={inActiveAlertStatus.every(value => selectedFilters.alertStatus.some(item => item.value === value))}
              sx={{
                color: "#808080",
                '&.Mui-checked': {
                  color: "#0562D2"
                },
                padding: "4px"
              }}
            />
            <ListItemText primary="All Inactive Alerts" primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }} />
          </MenuItem>
          {inActiveAlertStatus.map((option) => (
            <MenuItem
              sx={{
                width: "200px",
                padding: "8px",
                fontSize: "14px",
                color: "#333333",
                margin: 0,
                padding: "4px 24px",
              }}
              key={option} value={option} onClick={() => handleFilterChange('alertStatus', option)}>
              <Checkbox
                checked={selectedFilters.alertStatus.some(item => item.value === option)}
                sx={{
                  color: "#808080",
                  '&.Mui-checked': {
                    color: "#0562D2"
                  },
                  padding: "4px"
                }}
              />
              <ListItemText
                primary={option}
                primaryTypographyProps={{ sx: { fontSize: '14px', color: '#333333' } }}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  return (
    <Box sx={{ marginLeft: "-10px" }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderFilterDropdown('Customers', 'customerCategory', filterValues.customerCategory || [])}
        {renderFilterDropdown('Vehicle make', 'vehicleMake', filterValues.vehicleMake || [])}
        {renderFilterDropdown('Priority', 'priority', filterValues.priority || [])}
        {renderFilterDropdown('Alert Type', 'alertType', filterValues.alertType || [])}
        {renderAlertStatusDropdown('Alert Status')}
      </Box>
      <CustomerFilterChips
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filterValues={filterValues}
        globalCount={globalCount}
        currentFilterKey={currentFilterKey}
      />
    </Box>
  );
};

export default FilterSection;