import React, { useState, useMemo } from "react";

export const GlobalStateContext = React.createContext();

const GlobalStateProvider = ({ children }) => {
    const [customers, setCustomers] = useState([]);
    const [customerDetails, setCustomerDetails] = useState({});

    const value = useMemo(() => ({
        customers,
        setCustomers,
        customerDetails,
        setCustomerDetails
    }), [customers, customerDetails]);

    return (
        <GlobalStateContext.Provider value={value}>
            {children}
        </GlobalStateContext.Provider>
    );
}

export default GlobalStateProvider;
