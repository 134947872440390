import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { useRole } from '../../../context/RoleContext';
import { hasActionAccess, hasPageAccess } from '../../../utils/permissions';

//styling
import { styles } from "./IssueDetailprofile.style";
import CustomerNote from "./CustomerNote";
import customerService from "../../../services/customerService";
import VehicleInfo from "./VehicleInfo";

const IssueDetailProfile = ({ setIsApiError, customerDetails }) => {
  const [vehicleData, setVehicleData] = useState({});
  const [loading, setLoading] = useState(false);

  const { roleConfig } = useRole();
  const hasProfileVehiclePermission = hasActionAccess(roleConfig, 'alert_detail', 'profile_vehicle');

  const canReadNotes = hasPageAccess(roleConfig, 'customer_note', 'read');
  const canWriteNotes = hasPageAccess(roleConfig, 'customer_note', 'write');

  useEffect(() => {
     /* istanbul ignore next */
    const fetchVehicleInfo = async () => {
      setLoading(true)
      try {
        const response = await customerService.getVehicleInfo(customerDetails?.vehicleInfo?.vehicleProfileId);
        setVehicleData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setIsApiError({
          isError: true,
          message: error?.message,
          code: error?.response?.status,
        });
      } finally {
        setLoading(false);
      }
    };
  
    fetchVehicleInfo();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mx: 'auto' }}>
      {/* Customer Section */}
      <div style={{ margin: "10px" }}>
        <div style={{ margin: "10px 50px" }}>
          <div style={styles.profileHeadingContainer}>
            <div style={styles.profileHeadingText}>
              Customer Information
            </div>
            <div style={styles.profileTimestamp}>
              {customerDetails?.customerInfo?.lastUpdatedDate ? `Updated on ${moment(customerDetails?.customerInfo?.lastUpdatedDate).format('MM-DD-YYYY')}` : 'last updated at timestamp not available'}
            </div>
          </div>
          <table style={{ margin: "20px 0" }}>
            <tr>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Phone</p>
                <p style={styles.profileDataText}>
                  {"-"}
                </p>
              </td>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Email</p>
                <p style={styles.profileDataText}>
                  {
                    (
                      customerDetails?.customerInfo?.emailAddress === null ||
                      customerDetails?.customerInfo?.emailAddress === "" ||
                      customerDetails?.customerInfo?.emailAddress === undefined
                    ) ? "-" : customerDetails.customerInfo.emailAddress.toLowerCase()
                  }
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {/* Ford pass rewards */}
      <div style={{ margin: "5px 50px 0 50px", padding: "15px", background: "#FAFAFA" }}>
        <div>
          <div style={styles.profileHeadingContainer}>
            <div style={styles.profileHeadingText}>
              FordPass Rewards
            </div>
            <div style={styles.profileTimestamp}>
              {customerDetails?.customerInfo?.fordPassRewardsInfo?.pointsLastFetchedTime ? `Updated on ${moment(customerDetails?.customerInfo?.fordPassRewardsInfo?.pointsLastFetchedTime).format('MM-DD-YYYY')}` : 'last updated at timestamp not available'}
            </div>
          </div>
          <table style={{marginTop: "20px"}}>
            <tr>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Points</p>
                <p style={styles.profileDataText}>{customerDetails?.customerInfo?.fordPassRewardsInfo?.fordPassRewardsPoints ?? '-'}</p>
              </td>
              <td style={{ width: "250px" }}>
                <p style={styles.profileTimestamp}>Dollar Value</p>
                <p style={styles.profileDataText}>-</p>
              </td>
            </tr>
            <tr>
              <td style={{ width: "250px", paddingTop: "15px" }}>
                <p style={styles.profileTimestamp}>Expiry Date</p>
                <p style={styles.profileDataText}>
                {customerDetails?.customerInfo?.fordPassRewardsInfo?.pointsExpirationDate ? `${moment(customerDetails?.customerInfo?.fordPassRewardsInfo?.pointsExpirationDate).format('MM-DD-YYYY')}` : '-'}
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>

      {/* Customer Notes */}
      <CustomerNote 
        customerDetails={customerDetails} 
        canReadNotes={canReadNotes}
        canWriteNotes={canWriteNotes}
        setIsApiError={setIsApiError}
      />

      {/* Vehicle Information */}
      {hasProfileVehiclePermission && (
       <VehicleInfo customerDetails={customerDetails} vehicleData={vehicleData} loading={loading}  />
      )}
    </Box>
  );
};

IssueDetailProfile.propTypes = {
  setIsApiError: PropTypes.func,
};

export default IssueDetailProfile;