import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, IconButton } from '@mui/material';
import activityService from '../services/activityService'; 
import { getUserName } from '../config/AuthenticatedRoute';
import { getIdToken, clearTokens, isValidUrl } from '../utils/auth';
import DOMPurify from 'dompurify';
import { Button, SelectDropdown, Typography } from '@ford/ford-ui-components';
import CloseIcon from '@mui/icons-material/Close';

const PopupDialog = ({ open, onClose, onSelect, showCloseIcon = true }) => {
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedSiteCode, setSelectedSiteCode] = useState('');
  const [roles, setRoles] = useState([]);
  const [siteCodes, setSiteCodes] = useState([]);
  const [reason, setReason] = useState('');
  const [impersonateReasons, setImpersonateReasons] = useState([]);
  const [rcaUserSiteCode, setRcaUserSiteCode] = useState('');
  const name = getUserName(); 
  const logoutURL = process.env.REACT_APP_SIGNOUT_URL;
  const logoutParams = new URLSearchParams({
    id_token_hint: encodeURIComponent(getIdToken())
  }).toString();

  const handleLogout = () => {
    clearTokens();
    localStorage.clear();
    sessionStorage.clear();
    
    if (isValidUrl(logoutURL)) {
      const sanitizedUrl = DOMPurify.sanitize(`${logoutURL}?${logoutParams}`);
      window.location.href = sanitizedUrl;
    } else {
      /* istanbul ignore next */
      console.error('Invalid OAuth issuer Logout URL');
    }
  };

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          const response = await activityService.getImpersonateData();
          setRoles(response.data);
          const selectedRoleObj = response.data.find(role => role.role === selectedRole);
          if (selectedRoleObj) {
            setImpersonateReasons(selectedRoleObj.impersonateReasons || []);
          }
        } catch (error) {
          /* istanbul ignore next */
          console.error('Error fetching impersonate data:', error);
        }
      };
      fetchData();
    } else {
      // Reset state when dialog closes
      setSelectedRole('');
      setSelectedSiteCode('');
      setReason('');
      setSiteCodes([]);
      setImpersonateReasons([]);
    }
  }, [open]);

  /* istanbul ignore next */
  const handleRoleChange = (event) => {
    const selectedRoleName = event.value;
    setSelectedRole(selectedRoleName);

    const selectedRoleObj = roles.find(role => role.role === selectedRoleName);

    if (selectedRoleObj) {
      setSiteCodes(selectedRoleObj.siteCodeList);
      setImpersonateReasons(selectedRoleObj.impersonateReasons || []);
      if (selectedRoleName === 'RCAuser' || selectedRoleName === "Remote Care Assistant") {
        setSelectedSiteCode(rcaUserSiteCode);
      } else {
        if ((selectedRoleName === 'InternalAdmin' || selectedRoleName === 'CarePlus Internal Admin') && selectedSiteCode) {
          setRcaUserSiteCode(selectedSiteCode);
        }
        setSelectedSiteCode('');
      }
    } else {
      setSiteCodes([]);
      setImpersonateReasons([]);
      setSelectedSiteCode('');
    }
  };

  /* istanbul ignore next */
  const handleSiteCodeChange = (event) => {
    setSelectedSiteCode(event.value);
    if (selectedRole === 'RCAuser' || selectedRole === "Remote Care Assistant") {
      setRcaUserSiteCode(event.value);
    }
  };

  const handleReasonChange = (event) => {
    setReason(event.value);
  };

  /* istanbul ignore next */
  const handleConfirm = async () => {
    localStorage.removeItem("selectedFilters");
    if (selectedRole && (siteCodes.length === 0 || selectedSiteCode) && reason.trim() !== '') {

      const isDealerImpersonate = selectedRole === 'RCAuser' || selectedRole === "Remote Care Assistant" || selectedSiteCode !== '';
      try {
        await activityService.logActivity(selectedRole, 'login', selectedSiteCode, reason, isDealerImpersonate);
        sessionStorage.setItem('impersonateData', JSON.stringify({ role: selectedRole, siteCode: selectedSiteCode, isDealerImpersonate }));
        onSelect({ role: selectedRole, siteCode: selectedSiteCode });
        onClose();
        window.location.reload();
      } catch (error) {
        console.error('Error logging user activity:', error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose();
    }
  };

  /* istanbul ignore next */
  const isViewable = !selectedRole || (siteCodes.length > 0 && !selectedSiteCode) || reason.trim() === '';

  return (
    <Dialog fullWidth={true} open={open} onClose={handleClose} sx={{ overflow: 'visible' }}>
      <DialogTitle>Hi {name}, which role would you like to view?</DialogTitle>
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent sx={{ overflow: 'visible' }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Box sx={{ flex: 1 }}>
            <Typography displayColor="text-onlight-moderate-default" displayStyle="caption-regular">Select Role</Typography>
            <SelectDropdown
              emptyMessage="Fetching the Roles ...."
              value={selectedRole}
              onValueChange={handleRoleChange}
              options={roles.map((role) => ({
                label: role.role,
                value: role.role
              }))}
              showChevron
              testId='impersonateSelectRole'
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography displayColor="text-onlight-moderate-default" displayStyle="caption-regular">Select Dealer</Typography>
            <SelectDropdown
              disabled={siteCodes.length === 0}
              emptyMessage="Fetching the Dealer Sitecodes ...."
              value={siteCodes.length > 0 ? selectedSiteCode : ''}
              onValueChange={handleSiteCodeChange}
              options={siteCodes.map((siteCode) => ({
                label: siteCode,
                value: siteCode
              }))}
              showChevron
              testId='impersonateSelectDealer'
            />
          </Box>
        </Box>
        <Box mt={2}>
          <Typography displayColor="text-onlight-moderate-default" displayStyle="caption-regular">Reason for Viewing</Typography>
          <SelectDropdown
            emptyMessage="Fetching reasons..."
            value={reason}
            onValueChange={handleReasonChange}
            options={impersonateReasons.map((reason) => ({
              label: reason,
              value: reason
            }))}
            showChevron
            testId='impersonateSelectReason'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleLogout}
        >
          Logout
        </Button>
        <Button
          onClick={handleConfirm}
          variant='filled'
          color="primary"
          disabled={isViewable}
        >
          View
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;