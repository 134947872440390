import React from "react";

const Legend = ({ colorMapping, data }) => {
    // Calculate total count for percentage calculation
    const total = data.reduce((sum, item) => sum + item.count, 0);

    return (
        <div style={{
            top: '10px',
            right: '10px',
            fontSize: '12px',
        }}>
            {data.map((item) => {
                const key = item.statusName || item.alertCategory; // Determine the key name based on data structure
                const count = item.count;
                const percentage = ((count / total) * 100).toFixed(2);

                return (
                    <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                        <div style={{ width: '16px', height: '16px', backgroundColor: colorMapping[key], marginRight: '5px', borderRadius: "3px" }}></div>
                        <span style={{ fontSize: "16px", lineHeight: "21.6px", color:"#000000"}}>{key + " "}</span>
                        <span style={{ fontSize: "16px", lineHeight: "21.6px", color: "#808080", marginLeft: "5px"}}>({count}, {percentage}%)</span>
                    </div>
                );
            })}
        </div>
    );
};

export default Legend;
